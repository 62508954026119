import React from 'react';
import 'moment/locale/fr';
import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import { InstantSearch, Configure } from 'react-instantsearch-dom';
import LayoutStyles from '../../Layout/Styles/LayoutStyles';
import CustomPagination from './CustomPagination';
import { searchClient, INDEX_NAMES } from '../../../Services/AlgoliaHelper';
import CustomHitsOrganisationMainPage from './CustomHitsMainPage';

type Props = {
  searchAttributes: Object,
};

const useStyles = makeStyles(theme => ({
  layoutResults: {
    ...LayoutStyles.fullWidthLayout,
  },
  heroUnit: {
    paddingTop: 56,
    paddingBottom: theme.spacing(3),
    [`${theme.breakpoints.up('xs')} and (orientation: landscape)`]: {
      paddingTop: 48,
    },
    [theme.breakpoints.up('sm')]: {
      paddingTop: 64,
    },
  },
  layoutDiff: {
    display: 'flex',
    position: 'relative',
    alignItems: 'flex-start',
    paddingBottom: theme.spacing(3),
  },
}));

function InstantSearchResultsMain({ searchAttributes }: Props) {
  const classes = useStyles();

  const onPageClick = () => {
    if (window) {
      window.scrollTo({
        top: 55 + 110,
        left: 0,
      });
    }
  };

  return (
    <>
      <Grid className={classes.layoutResults}>
        <Grid className={classes.heroUnit}>
          <Grid>
            <InstantSearch searchClient={searchClient} indexName={INDEX_NAMES.ORGANISATION}>
              <Grid
                container
                spacing={6}
                itemScope
                itemType="http://schema.org/ItemList"
                className={classes.layoutDiff}
              >
                <CustomHitsOrganisationMainPage />
                <Configure {...searchAttributes} />
              </Grid>
              <CustomPagination onPageClick={onPageClick} pageType="organisation" />
            </InstantSearch>
          </Grid>
        </Grid>
      </Grid>
    </>
  );
}

export default InstantSearchResultsMain;
