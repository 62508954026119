import React from 'react';
import { useTranslation } from 'react-i18next';
import { connectHits } from 'react-instantsearch-dom';

import Typography from '@material-ui/core/Typography';
import _ from 'lodash';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import { makeStyles } from '@material-ui/core/styles';
import CardOrganisation from '../../Organisations/Shared/CardOrganisation';

type HitsMainPageProps = {
  hits: Object,
};

const useStyles = makeStyles(theme => ({
  title: {
    paddingBottom: theme.spacing(3),
    paddingLeft: theme.spacing(1),
  },
}));

/* Organisations */

function HitsOrganisationMainPage({ hits }: HitsMainPageProps) {
  const classes = useStyles();
  const { t } = useTranslation();
  if (_.isEmpty(hits)) return null;

  return (
    <Grid item xs={12}>
      <Typography variant="h3" component="h1" align="left" gutterBottom className={classes.title}>
        {t('APP.ALL_ORGANISATIONS_PAGE.TITLE')}
      </Typography>
      <Grid container>
        {hits.map(hit => {
          const organisation = {
            id: hit.objectID,
            name: hit.a_name,
            slug: hit.a_slug,
            holding_slug: hit.a_holding_slug,
            banner_url: hit.a_banner,
            formatted_address: hit.a_formatted_address,
            holding: {
              short_description: hit.a_short_description,
              name: hit.a_holding_name,
              cover_url: hit.a_avatar,
            },
            services:
              hit.a_service &&
              hit.a_service.map(a => ({ service: { label: a.label, slug: a.slug } })),
            sampled_helpers: hit.a_helpers?.map(h => ({
              avatar_url: h,
            })),
            type: hit.a_type,
            helper_count: hit.a_helper_count,
          };

          return (
            <Grid item xs={12} sm={4} md={3} key={hit.objectID}>
              <Paper style={{ padding: '10px' }} elevation={0}>
                <CardOrganisation organisation={organisation} hideMembers />
              </Paper>
            </Grid>
          );
        })}
      </Grid>
    </Grid>
  );
}

const CustomHitsOrganisationMainPage = connectHits(HitsOrganisationMainPage);

export default CustomHitsOrganisationMainPage;
