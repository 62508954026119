import React from 'react';
import { useTranslation } from 'react-i18next';

import { Helmet } from 'react-helmet';
import 'moment/locale/fr';
import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Divider from '@material-ui/core/Divider';
import Footer from '../../Components/Layout/Footer';
import LayoutStyles from '../../Components/Layout/Styles/LayoutStyles';
import Routes from '../../Routing/Routes';

import Seo from '../../Routing/Seo';
import { FILTERING_ATTRIBUTES } from '../../Services/AlgoliaHelper';
import InstantSearchResultsMain from '../../Components/Shared/Algolia/InstantSearchResultsMain';

const useStyles = makeStyles(theme => ({
  subtitle: { paddingBottom: theme.spacing(2) },
  mainLayout: {
    backgroundColor: 'white',
  },
  layout: {
    ...LayoutStyles.fullWidthLayout,
    paddingTop: '30px',
  },
  layoutResults: {
    ...LayoutStyles.fullWidthLayout,
  },
  searchBar: {
    marginTop: 0,
  },
  pagination: {
    paddingTop: theme.spacing(3),
  },
  join: {
    ...LayoutStyles.fullWidthLayout,
    paddingTop: theme.spacing(8),
    paddingBottom: theme.spacing(8),
    paddingLeft: theme.spacing(1),
  },
  button: {
    marginTop: theme.spacing(3),
  },
  greyBackground: {
    backgroundColor: theme.palette.grey[50],
  },
}));

function SearchResultsMain() {
  const classes = useStyles();
  const { t } = useTranslation();

  return (
    <Grid className={classes.mainLayout}>
      <Helmet>
        {Seo.title(
          `${t(`APP.SEO.SEARCH_RESULT_PAGE.TITLE`)}${t(`${Routes.currentNamespace()}.SEO.SUFFIX`)}`,
        )}
      </Helmet>
      <Divider light />
      <InstantSearchResultsMain
        searchAttributes={{
          hitsPerPage: 20,
          filters: `${FILTERING_ATTRIBUTES.IS_WELLO_ATTRIBUTE}:1`,
        }}
      />
      <Footer />
    </Grid>
  );
}

export default SearchResultsMain;
